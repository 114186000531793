<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-row>
          <v-col cols="12" md="12">
            <p
                class="title main-color"
                style="text-align: left"
                v-if="grade_name"
            >
              {{ grade_name }} : {{ subject_name }} : {{ qualifier_name }}
            </p>
            <p class="title" style="text-align: left" v-if="quarter_name">
              {{ quarter_name }}
            </p>
          </v-col>
        </v-row>
        
        <!-- Body -->
        <v-row>
          <v-col md="12" class="">
            <v-card >
              
              <div class="v-data-table elevation-1 level1 v-data-table--has-top theme--light">
              <div class="v-data-table__wrapper">
                
                <table class="table display data-table">
                  <thead class="v-data-table-header vertical_align_center">
                  <tr style="line-height: 3">
                    <th role="columnheader" scope="col" aria-sort="none" class="text-center align-center table-header">
                      <span>
                        {{ $t("Class") }}
                      </span>
                    </th>
                    <th v-for="(item, index) in qualifier_activities" :key="index" role="columnheader" scope="col"
                        aria-sort="none" class="text-center align-center table-header">
                      <span>
                        {{ item.name }}
                      </span>
                    </th>
                    <th v-if="!qualifier_activities.length" class="text-center align-center table-header"> There are no Activities</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in user_related_classes" :key="index" class="text-center align-center">
                    <td>{{ item.name }}</td>
                    <td v-for="(item, index) in qualifier_activities" :key="index"></td>
                    <td v-if="!qualifier_activities.length" class="text-center align-center table-header"> </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "QualifierClassActivityExamAssignment",
  
  data() {
    return {
      academic_grade_id: "",
      subject_qualifier_quarter_id: "",
      quarter_name: "",
      grade_name: "",
      subject_name: "",
      qualifier_name: "",
      user_related_classes: [],
      qualifier_activities: [],
    };
  },
  watch: {},
  methods: {
    getAllData() {
      axios
          .get(
              this.getApiUrl +
              "/performance/quarter/grade/subject/qualifier/exam-assignment/" +
              this.academic_grade_id +
              "/" +
              this.subject_qualifier_quarter_id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                  //the token is a variable which holds the token
                },
              }
          )
          .then((response) => {
            // this.subjects = [];
            this.quarter_name = response.data.data.quarter_name;
            this.grade_name = response.data.data.grade_name;
            this.subject_name = response.data.data.subject_name;
            this.qualifier_name = response.data.data.qualifier_name;
            this.user_related_classes = response.data.data.user_related_classes;
            this.qualifier_activities = response.data.data.qualifier_activities;
          });
    },
  },
  mounted() {
    this.academic_grade_id = this.$router.currentRoute.params.academic_grade_id;
    this.subject_qualifier_quarter_id = this.$router.currentRoute.params.subject_qualifier_quarter_id;
    this.getAllData();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

// end search input
.title-page {
  margin-top: 15px;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.card-container {
  max-width: 80%;
  border-radius: 10px;
  position: relative;
  margin-bottom: 50px;
}

.card-container .actions {
  position: absolute;
  top: -39px;
  right: 22px;
}

.card-container .actions i,
.card-container .actions span {
  cursor: pointer;
}

.card-container .actions span {
  margin-left: 5px;
}

.title-qualifire .divider {
  margin-top: 10px;
  margin-bottom: 10px;
}

.q-box-title {
  font-size: 29px;
  position: relative;
}

.q-box-title h2 {
  font-size: 25px;
  padding: 0;
  margin: 0;
}

.title-page {
  margin-top: 15px;
}

.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #eef3ff;
  margin-bottom: 33px;
}

.v-expansion-panel-content {
  background-color: #f7f7f7;
}

.title-panel {
  color: rgb(114, 151, 255);
  margin: 0;
  margin-top: 15px;
  padding: 0;
}

.details {
  margin-top: 6%;
  overflow: hidden;
}

.details span {
  color: #8d8a8a;
  font-size: 17px;
  font-weight: 700;
}

.details span.passing {
  float: right;
}

.v-expansion-panel-content__wrap {
  padding: 0;
}

.table-header {
  color:  rgba(0, 0, 0, 0.6);
}
</style>
